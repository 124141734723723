import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/belshifa-admin/page/home',
    title: 'Home',
    icon: 'ft-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin', "CallCenter"],
    submenu: [],
    
  },
  {
    path: '/belshifa-admin/page/admins',
    title: 'Admins',
    icon: 'ft-users',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin'],
    submenu: [],
    
  },
  {
    path: '/belshifa-admin/page/ads',
    title: 'Ads',
    icon: 'ft-target',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin'],
    submenu: [],
  },
  {
    path: '/belshifa-admin/page/offers',
    title: 'Offers',
    icon: 'ft-target',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin'],
    submenu: [],
  },
  {
    path: '/belshifa-admin/page/users',
    title: 'Users',
    icon: 'ft-users',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin', 'CallCenter'],
    submenu: [],
  },
  {
    path: '/belshifa-admin/page/products',
    title: 'Products',
    icon: 'ft-shopping-cart',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: ['SuperAdmin'],
    submenu: [],
  },
  {
    path: '/belshifa-admin/page/categories',
    title: 'Categories',
    icon: 'ft-target',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin'],
    submenu: [],
  },
  {
    path: '/belshifa-admin/page/sub-categories',
    title: 'Sub-Categories',
    icon: 'ft-target',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin'],
    submenu: [],
  },
  {
    path: '/belshifa-admin/page/cities',
    title: 'Cities',
    icon: 'ft-target',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin', 'CallCenter'],
    submenu: [],
  },
  {
    path: '/belshifa-admin/page/areas',
    title: 'Areas',
    icon: 'ft-target',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin', 'CallCenter'],
    submenu: [],
  },
  {
    path: '/belshifa-admin/page/classifications',
    title: 'Classifications',
    icon: 'ft-target',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin'],
    submenu: [],
  },
  {
    path: '/belshifa-admin/page/industryCompanies',
    title: 'Industry Company',
    icon: 'ft-target',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin'],
    submenu: [],
  },
  {
    path: '/belshifa-admin/page/useways',
    title: 'UseWays',
    icon: 'ft-target',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin'],
    submenu: [],
  },
  {
    path: '/belshifa-admin/page/scientificgroups',
    title: 'Scientific Groups',
    icon: 'ft-target',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin'],
    submenu: [],
  },
  {
    path: '/belshifa-admin/page/itemsdiscount',
    title: 'Items Discount',
    icon: 'ft-target',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin'],
    submenu: [],
  },
  {
    path: '/belshifa-admin/page/settings',
    title: 'Settings',
    icon: 'ft-settings',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin'],
    submenu: [
      {
        path: '/belshifa-admin/page/configuration',
        title: 'Configurations',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        roles:['SuperAdmin'],
        submenu: [],
      }, {
        path: '/belshifa-admin/page/mobileversions',
        title: 'Mobile Versions',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        roles:['SuperAdmin'],
        submenu: [],
      },]
  },
  {
    path: '/belshifa-admin/page/orders',
    title: 'Orders',
    icon: 'ft-target',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin'],
    submenu: [],
  },
  {
    path: '/belshifa-admin/page/payments',
    title: 'Payments',
    icon: 'ft-target',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin'],
    submenu: [],
  },
  {
    path: '/belshifa-admin/page/reports',
    title: 'Reports',
    icon: 'ft-target',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin', 'CallCenter'],
    submenu: [
      {
        path: '/belshifa-admin/page/customersalesorderreport',
        title: 'Customer Sales Order Report',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        roles:['SuperAdmin', 'CallCenter'],
        submenu: [],
      }, {
        path: '/belshifa-admin/page/orderitemsreport',
        title: 'Order Items Report',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        roles:['SuperAdmin', 'CallCenter'],
        submenu: [],
      },
    ],
  },
  {
    path: '/belshifa-admin/page/pharmacies',
    title: 'Pharmacies',
    icon: 'ft-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin', 'CallCenter'],
    submenu: [],
  },
  {
    path: '/belshifa-admin/page/branches',
    title: 'Branches',
    icon: 'ft-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles:['SuperAdmin', 'CallCenter'],
    submenu: [],
  },
];
